import type {FC} from 'react';

import {GalleryContainer} from '@/modules/page/sanity/content-blocks/gallery-container';
import {LinkListSelectionContainer} from '@/modules/page/sanity/content-blocks/link-list-selection-container';
import {TextContainer} from '@/modules/page/sanity/content-blocks/text-container';
import {TextWithImageSplitContainer} from '@/modules/page/sanity/content-blocks/text-with-image-split-container';
import type {SanityArray} from '@/sanity/lib/types';

import type {ContentBlockFragment} from './query';

interface Props {
	content: SanityArray<ContentBlockFragment>;
}

const ContentBlocks: FC<Props> = ({content}) => {
	const sections = content?.map((section) => {
		switch (section._type) {
			case 'textSection':
				return <TextContainer key={section._key} data={section} />;
			case 'textWithImageSplit':
				return <TextWithImageSplitContainer key={section._key} data={section} />;
			case 'gallery':
				return <GalleryContainer key={section._key} data={section} />;
			case 'linkListSelection':
				return <LinkListSelectionContainer key={section._key} data={section} />;
			default:
				return null;
		}
	});

	return <>{sections}</>;
};

export default ContentBlocks;
