import {type HTMLAttributes} from 'react';
import type {VariantProps} from 'class-variance-authority';
import {cva} from 'class-variance-authority';
import {twMerge} from 'tailwind-merge';

export type ContainerSpacing = 'none' | 'small' | 'normal' | 'large';

/**
 * @name Container
 * @description
 * * Renders a 3 column grid container.
 * * Children span the middle column.
 * * To make  a direct child like an image span to the edge of the screen,
 * set the className of the child to "!col-span-full".
 *
 * To use this component, the `tailwind.config.js` file must be updated to include the following:
 * ```js
 * {
 *   theme: {
 *     extend: {
 *       gridTemplateColumns: {
 *         'container-text': 'minmax(24px, 1fr) minmax(auto, 768px) minmax(24px, 1fr)',
 *         'container-normal': 'minmax(24px, 1fr) minmax(auto, 1280px) minmax(24px, 1fr)',
 *         'container-full': 'minmax(24px, 1fr) minmax(auto, 100%) minmax(24px, 1fr)',
 *       },
 *     },
 *   },
 * }
 * ```
 *
 * @param {string} [as] - The HTML tag to render the container as, defaults to 'div'.
 * @param {string} [width] - The width of the container. Can be 'text', 'normal', or 'full'.
 * @param {string} [spacingTop] - The top margin of the container. Can be 'none', 'small', 'normal', or 'large'.
 * @param {string} [spacingBottom] - The bottom margin of the container. Can be 'none', 'small', 'normal', or 'large'.
 * @param {boolean} [hasBackground] - Whether the container has a background.
 * @param {ReactNode} children - The children to render inside the container.
 * @param {string} [className] - The class name to apply to the container.
 * @param {HTMLAttributes<HTMLElement>} [rest] - The rest of the props to apply to the container.
 * @returns {React.ReactElement} The container component.
 */

const container = cva(
	['relative', 'grid', 'items-center', '[&>*]:col-start-2', '[&>*]:col-end-2'],
	{
		variants: {
			width: {
				text: 'grid-cols-container-text',
				normal: 'grid-cols-container-normal',
				full: 'grid-cols-container-full',
			},
		},
	},
);

export type ContainerProps = VariantProps<typeof container> &
	HTMLAttributes<HTMLElement> & {
		as?: 'div' | 'header' | 'section' | 'article' | 'aside' | 'footer' | 'ul';
		hasBackground?: boolean;
		spacingTop?: ContainerSpacing;
		spacingBottom?: ContainerSpacing;
	};

export const Container = (props: ContainerProps) => {
	const {
		as,
		children,
		width = 'normal',
		className,
		spacingBottom,
		spacingTop,
		hasBackground,
		...rest
	} = props;

	const Component = as ?? 'div';

	let spacingBottomString = '';
	let spacingTopString = '';

	switch (spacingBottom) {
		case 'none':
			spacingBottomString = hasBackground ? 'pb-0' : 'mb-0';
			break;
		case 'small':
			spacingBottomString = hasBackground ? 'pb-4' : 'mb-4';
			break;
		case 'normal':
			spacingBottomString = hasBackground ? 'pb-8' : 'mb-8';
			break;
		case 'large':
			spacingBottomString = hasBackground ? 'pb-12' : 'mb-12';
			break;
	}

	switch (spacingTop) {
		case 'none':
			spacingTopString = hasBackground ? 'pt-0' : 'mt-0';
			break;
		case 'small':
			spacingTopString = hasBackground ? 'pt-4' : 'mt-4';
			break;
		case 'normal':
			spacingTopString = hasBackground ? 'pt-8' : 'mt-8';
			break;
		case 'large':
			spacingTopString = hasBackground ? 'pt-12' : 'mt-12';
			break;
	}

	return (
		<Component
			className={twMerge(
				container({
					width,
					className: [spacingBottomString, spacingTopString, className],
				}),
			)}
			{...rest}
		>
			{children}
		</Component>
	);
};

Container.displayName = 'Container';
