import type {FC} from 'react';
import clsx from 'clsx';

import ImageFill from '@/modules/foundation/components/image/ImageFill';
import type {ImageWeb} from '@/modules/foundation/components/image/types';
import {Link} from '@/modules/foundation/components/link';
import {Paragraph} from '@/modules/foundation/components/typography/paragraph';

interface Props {
	href: string;
	title: string;
	image?: ImageWeb;
}

const LinkCard: FC<Props> = ({image, href, title}) => {
	return (
		<Link href={href}>
			<div className={clsx('flex', 'flex-col', 'text-center')}>
				<div
					className={clsx(
						'relative',
						'size-[200px]',
						'rounded-full',
						'overflow-hidden',
						'mb-2',
					)}
				>
					{image && (
						<ImageFill
							src={image.image.src}
							width={image.image.width}
							height={image.image.height}
							hotspot={image.image.hotspot}
							alt={image.altText ?? ''}
						/>
					)}
				</div>
				<Paragraph size="xlarge" className="text-primary-dark">
					{title}
				</Paragraph>
			</div>
		</Link>
	);
};

export default LinkCard;
