import type {FC} from 'react';
import React from 'react';
import clsx from 'clsx';
import type {ControlProps} from 'nuka-carousel';

import {ArrowLeftIcon} from '@/modules/foundation/components/icons/arrow-left-icon';
import {ArrowRightIcon} from '@/modules/foundation/components/icons/arrow-right-icon';

const buttonBase = clsx(
	'cursor-pointer',
	'disabled:cursor-not-allowed',
	'flex',
	'appearance-none',
	'items-center',
	'justify-center',
	'opacity-90',
	'hover:opacity-100',
	'disabled:opacity-30',
	'bg-primary-dark',
	'rounded-full',
	'text-primary-light',
	'size-10',
);

export const renderCenterLeftControls: FC<ControlProps> = ({previousDisabled, previousSlide}) => (
	<button
		className={clsx(buttonBase, '-ml-4', 'md:ml-4')}
		disabled={previousDisabled}
		onClick={previousSlide}
		aria-label="Gå til forrige slide"
	>
		<ArrowLeftIcon />
	</button>
);

export const renderCenterRightControls: FC<ControlProps> = ({nextDisabled, nextSlide}) => (
	<button
		className={clsx(buttonBase, '-mr-4', 'md:mr-4')}
		disabled={nextDisabled}
		onClick={nextSlide}
		aria-label="Gå til neste slide"
	>
		<ArrowRightIcon />
	</button>
);
