import type {FC} from 'react';
import React from 'react';
import {cva} from 'class-variance-authority';
import type {ControlProps} from 'nuka-carousel';
import {twMerge} from 'tailwind-merge';

import {ArrowLeftIcon} from '@/modules/foundation/components/icons/arrow-left-icon';
import {ArrowRightIcon} from '@/modules/foundation/components/icons/arrow-right-icon';

const centerControls = cva(
	[
		'border-none',
		'bg-transparent',
		'cursor-pointer',
		'disabled:cursor-not-allowed',
		'flex',
		'appearance-none',
		'items-center',
		'text-white',
		'opacity-70',
		'hover:opacity-100',
		'disabled:opacity-30',
		'absolute',
		'max-sm:top-full',
	],
	{
		variants: {
			intent: {
				left: ['max-sm:left-[20px]', 'sm:right-full'],
				right: ['max-sm:right-[20px]', 'sm:left-full'],
			},
		},
	},
);

export const renderCenterLeftControls: FC<ControlProps> = ({previousDisabled, previousSlide}) => (
	<button
		className={twMerge(centerControls({intent: 'left'}))}
		disabled={previousDisabled}
		onClick={previousSlide}
		aria-label="Gå til forrige slide"
	>
		<ArrowLeftIcon color="white" />
	</button>
);

export const renderCenterRightControls: FC<ControlProps> = ({nextDisabled, nextSlide}) => (
	<button
		className={twMerge(centerControls({intent: 'right'}))}
		disabled={nextDisabled}
		onClick={nextSlide}
		aria-label="Gå til neste slide"
	>
		<ArrowRightIcon color="white" />
	</button>
);
